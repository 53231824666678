<template>
  <div class="router-view"><router-view /></div>
</template>

<style scoped>
.router-view {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  text-align: center;
  background: url('./assets/images/background.png') no-repeat center center;
  background-size: cover;
  color: white;
}
</style>
