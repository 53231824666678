<template>
  <div class="video-container">
    <video ref="video" poster="../assets/images/thumb-keuze.jpg" playsinline @click="play" @timeupdate="checkLoop">
      <source v-if="isBirthday" src="https://verjaardagvideo.qmusic.be/keuze-vandaag-nl.webm" type="video/webm" />
      <source v-else src="https://verjaardagvideo.qmusic.be/keuze-nl.webm" type="video/webm" />
      Your browser does not support the video tag.
    </video>
    <div v-if="inLoop" class="overlay">
      <div class="option option1" @click="selectOption(0)" />
      <div class="option option2" @click="selectOption(1)" />
    </div>
  </div>
  <div class="emojis">
    <img src="../assets/images/emojis-left.png" alt="emojis" />
    <img src="../assets/images/emojis-right.png" alt="emojis" />
  </div>
</template>

<script setup>
import { dataLayer } from '@dpgradio/creative'
import { ref } from 'vue'

const video = ref(null)
const loopIndex = ref(0)
const answers = ref({})
const loops = [
  { start: 23.075, end: 34.525, restart: 35.475 },
  { start: 51.2, end: 63.2, restart: 63.225 },
  { start: 80.1, end: 92.05, restart: 92.125 },
  { start: 108.3, end: 120.275, restart: 120.325 },
]

const inLoop = ref(false)
const key = new URLSearchParams(window.location.search).get('key')
const profileId = ref(null)
let isBirthday = ref(false)

if (key && key.length > 4) {
  profileId.value = key.slice(0, -4)
  const birthday = key.slice(-4)
  const month = parseInt(birthday.slice(0, 2), 10)
  const day = parseInt(birthday.slice(2, 4), 10)
  const today = new Date()
  isBirthday.value = today.getDate() === day && today.getMonth() + 1 === month
} else {
  console.error('Invalid key or key does not contain a valid birthday')
}

function play() {
  video.value.play()
}

function checkLoop() {
  const currentTime = video.value.currentTime
  const currentLoop = loops[loopIndex.value]

  if (!currentLoop) return

  inLoop.value = currentTime >= currentLoop.start - 1 && currentTime < currentLoop.end
  if (currentTime >= currentLoop.end) {
    video.value.currentTime = currentLoop.start
  }
}

function selectOption(option) {
  answers.value[loopIndex.value] = option
  dataLayer.push({
    event: 'option_choice',
    question: loopIndex.value,
    video_choice: option,
  })
  nextLoop()
}
function nextLoop() {
  video.value.currentTime = loops[loopIndex.value].restart
  loopIndex.value++
  video.value.play()
  if (loopIndex.value >= loops.length) {
    sendAnswers()
  }
}

function sendAnswers() {
  fetch('https://api.qmusic.nl/2.6/members/me/birthday_video_choice', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      id: new URLSearchParams(window.location.search).get('key').slice(0, -4),
      choices: answers.value,
    }),
  })
    .then((data) => {
      console.log('Success:', data)
    })
    .catch((error) => {
      console.error('Error:', error)
    })
}
</script>

<style lang="scss" scoped>
.video-container {
  margin-top: -50px;
  width: 100%;
  max-width: 800px;
  position: relative;

  video {
    width: 100%;
    height: auto;
  }
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .option {
    width: 50%;
    height: 100%;
    cursor: pointer;
  }
}

.emojis {
  width: calc(100% + 300px);
  max-width: 1300px;
  margin: -110px -150px 0;
  display: flex;
  position: relative;
  justify-content: space-between;

  img {
    height: 150px;
  }
}

@media (max-width: 760px) {
  .emojis {
    display: none;
  }

  .video-container {
    margin: 0 auto;
    max-width: 95%;
  }
}
</style>
