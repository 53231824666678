import { createApp } from 'vue'
import { configuration, dataLayer, privacy } from '@dpgradio/creative'
import './style.css'
import App from './App.vue'
import router from './router'

const initialize = async () => {
  try {
    await configuration.retrieveConfigForStation('qmusic_nl')
  } catch (error) {
    alert(`
      Could not load configuration.
      - Have you set up a configuration for this application in Dario (see [updateConfigSchema.js])? If you do not want a custom configuration, remove the application name from the [configuration.retrieveConfigForDetectedStation] call in [main.js].
      - Did you provide a station name? (e.g. through the URL parameter 'stationId') If you want to set the station name in code, replace the configuration retrieval call with [configuration.retrieveConfigForStation('<station-name>', 'qmusic-nl-birthday-2025')] in [main.js]. 

      ${error}
    `)
  }
  const app = createApp(App)

  app.use(router)
  app.mount('#app')

  privacy.initialize()
  dataLayer.initialize()
  dataLayer.pushVirtualPageView()
}

initialize()
