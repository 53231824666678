<script setup>
import { hybrid } from '@dpgradio/creative'

const key = new URLSearchParams(window.location.search).get('key')

const url = `https://birthday.qmusic.nl/?key=${key}`

const openLink = () => {
  hybrid.call('openUrl', { url, mode: 'overlay' })
}
</script>

<template>
  <div class="message-container">
    <div class="button" @click="openLink">Vul in 🎂!</div>
  </div>
</template>

<style scoped>
@import '@dpgradio/creative/styles/all.css';

body {
  background-color: transparent;
}

.message-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  background: white;
  width: 100%;
}

.button {
  text-decoration: none;
  padding-left: 2rem;
  padding-right: 2rem;
  padding-bottom: 0.5rem;
  padding-top: 0.75rem;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  border-radius: 9999px;
  font-size: 1.875rem;
  line-height: 2.25rem;
  font-weight: 700;
  color: #ffffff;
  text-transform: uppercase;
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
  background-color: rgb(var(--q-red));
  font-family: 'Cervo';
  letter-spacing: -0.25px;
}
</style>
