import { createRouter, createWebHistory } from 'vue-router'
import Selection from './pages/Selection.vue'
import Result from './pages/Result.vue'
import Button from './pages/button.vue'

const routes = [
  { path: '/', component: Selection },
  { path: '/proficiat', component: Result },
  { path: '/button', component: Button },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

export default router
