<template>
  <div>
    <h1>Hieperdepiep</h1>
    <h2>dit is jouw persoonlijke Q-verrassingsfeest!</h2>
    <p>
      Van harte gefeliciteerd met je verjaardag. De Qmusic-dj’s zijn aan de slag gegaan met jouw verjaardagswensen.
      Benieuwd wat ze voor je in petto hebben? Check de video hieronder!
    </p>
    <div class="video-wrapper">
      <video ref="video" class="video" poster="../assets/images/thumb-result.jpg" @click="play">
        <source :src="`https://verjaardagvideo.qmusic.be/${key}`" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
    <div class="inflatables">
      <video ref="inflatable" autoplay muted loop src="../assets/videos/inflatable.mp4" @loadedmetadata="setTime" />
      <video autoplay muted loop src="../assets/videos/inflatable.mp4" />
    </div>
  </div>
</template>

<script setup>
import { dataLayer } from '@dpgradio/creative'
import { ref } from 'vue'

const video = ref(null)
const inflatable = ref(null)
const key = new URLSearchParams(window.location.search).get('key')

function setTime() {
  inflatable.value.currentTime = 5
}

function play() {
  video.value.play()
  dataLayer.push({
    event: 'video_play',
  })
}
</script>

<style lang="scss" scoped>
h1 {
  font-size: 7rem;
  margin-bottom: 0;
}

h2 {
  font-family: BlackBones;
  margin: 0;
  padding: 0;
  font-size: 5rem;
  line-height: 5rem;
  font-weight: 400;
  color: rgb(var(--q-yellow));
  transform-origin: 0 0;
  transform: rotate(-3deg);
  position: relative;
  top: -30px;
}

p {
  max-width: 800px;
  font-size: 1.3rem;
  margin-top: 0;
  position: relative;
  margin: auto;
}

video {
  width: 100%;
  max-width: 800px;
  position: relative;
  margin-top: 20px;
}

.inflatables {
  justify-content: space-between;
  pointer-events: none;

  video {
    pointer-events: none;
    position: absolute;
    height: 80vh;
    max-width: none;
    bottom: -35px;

    &:nth-child(1) {
      left: -35%;
    }

    &:nth-child(2) {
      right: -35%;
    }
  }
}

@media (orientation: landscape) and (max-width: 1000px) {
  .video-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: black;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .video-wrapper video {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }
}

@media (max-width: 1300px) {
  .inflatables {
    display: none;
  }
}

@media (max-width: 760px) {
  h1 {
    font-size: 5rem;
    line-height: 4rem;
  }

  h2 {
    font-size: 3rem;
    line-height: 3rem;
    top: -10px;
  }

  p {
    font-size: 1rem;
    top: 0px;
    max-width: 90%;
  }

  video {
    margin-top: 10px;
  }
}
</style>
